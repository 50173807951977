import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

import { createBrowserHistory } from "history";
import { iconLogo as IconLogo } from "@shared/components/lib";
import { Typography } from "@mui/material";
import theme from "../theme";

const Search = ({
  tracking: initialTrackingCode,
  postcode: initialZipCode,
  error,
}) => {
  const [trackingCode, setTrackingCode] = useState(initialTrackingCode);
  const [zipCode, setZipcode] = useState(initialZipCode);
  const [trackingCodeError, setTrackingCodeError] = useState("");
  const [zipcodeError, setZipcodeError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const history = createBrowserHistory({ forceRefresh: true });

  useEffect(() => {
    if (error) {
      if (error.code === "invalid-argument") {
        return setTrackingCodeError(error.message);
      }
      if (error.code === "invalid-combination") {
        return setZipcodeError(error.message);
      }
    }
  }, [error]);

  const submit = async (event, trackingCode, zipCode) => {
    event.preventDefault();
    setErrorMsg("");
    setIsDisabled(true);
    history.push({ pathname: `/${trackingCode}/${zipCode}` });
  };

  const handleInputValue = (event) => {
    const { value } = event?.currentTarget;
    if (!value) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    return value;
  };

  const onTrackingCodeChangeHandler = (event) => {
    const value = handleInputValue(event);
    setTrackingCodeError("");
    setTrackingCode(value);
  };

  const onZipCodeChangeHandler = (event) => {
    const value = handleInputValue(event);
    setZipcodeError("");
    setZipcode(value);
  };

  return (
    <>
      <Grid container component="main" sx={styles.root}>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          sm={12}
          md={6}
          direction="column"
          sx={styles.leftContainer}
          rowSpacing={5}
        >
          <Grid item sx={styles.logo}>
            <IconLogo />
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="space-around"
            sx={styles.formContainer}
          >
            <Typography sx={styles.follow}>Follow your package</Typography>
            {errorMsg && (
              <Grid container justifyContent="start" sx={styles.errorMsg}>
                <Alert severity="error" icon={false}>
                  {errorMsg}
                </Alert>
              </Grid>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Tracking code"
              name="trackingCode"
              value={trackingCode}
              inputProps={{
                autoComplete: "off",
              }}
              id="trackingCode"
              error={trackingCodeError}
              helperText={trackingCodeError}
              autoFocus
              onPaste={(event) => onTrackingCodeChangeHandler(event)}
              onChange={(event) => onTrackingCodeChangeHandler(event)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputProps={{
                autoComplete: "off",
              }}
              value={zipCode}
              name="zipcode"
              label="Zipcode"
              type="text"
              error={zipcodeError}
              helperText={zipcodeError}
              onPaste={(event) => onZipCodeChangeHandler(event)}
              onChange={(event) => onZipCodeChangeHandler(event)}
            />
            <Button
              fullWidth
              variant="outlined"
              disabled={isDisabled}
              onClick={(event) => {
                submit(event, trackingCode, zipCode);
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid />
        </Grid>
        <Grid item md={6} sx={styles.imageFirst} />
      </Grid>
    </>
  );
};

const styles = {
  root: {
    height: "100vh",
  },
  logo: {
    "&.MuiGrid-item": {
      padding: "10px 0 60px",
    },
  },
  formContainer: {
    maxWidth: "360px",
    height: "300px",
  },
  imageFirst: {
    backgroundImage: "url(Bg.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    minHeight: "100%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  errorMsg: {
    margin: 2,
    "& .MuiAlert-root": {
      fontSize: "17px",
    },
  },
  leftContainer: {
    justifyContent: "center",
  },
  follow: {
    color: theme.palette.secondary.dark,
    fontSize: 20,
    fontWeight: "bold",
  },
};

export default Search;
