import React, { useContext, useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "react-query";
import { getRequestUI, postRequestUI } from "common-utils/utils/api";
import { OrderContext } from "../context";
import Link from "@mui/material/Link";
import theme from "../theme";
import Grid from "@mui/material/Grid";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import VerificationInput from "react-verification-input";
import { formatPostCode, formatAddress } from "common-utils/utils/formatter";
import MenuItem from "@mui/material/MenuItem";
import { defaultCountry } from "common-utils/utils/country";
import _ from "lodash";
import "../index.css";
import { isPostcodeValid } from "common-utils/utils/validation";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DateTime } from "luxon";
import Box from "@mui/material/Box";

const ChangeOrderDeliveryAddress = () => {
  const { order, setOrder, serReferenceOrders } = useContext(OrderContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [address, setAddress] = useState({
    houseNumber: undefined,
    houseAddition: undefined,
    postcode: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [resolvedAddress, setResolvedAddress] = useState({});
  const [houseAdditions, setHouseAdditions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationMethod, setVerificationMethod] = useState("email");

  const delayedAddressChecked = useRef(
    _.debounce(
      async (postcode, houseNumber) =>
        await addressChanged(postcode, houseNumber),
      300
    )
  ).current;

  useEffect(() => {
    if (order) {
      handleSetOrderDelivery(order);
    }
  }, [order]);

  useEffect(() => {
    delayedAddressChecked(address.postcode, address.houseNumber);
  }, [address.postcode, address.houseNumber]);

  const handleSetOrderDelivery = (order) => {
    setAddress({
      postcode: order.deliveryAddress.postcode,
      houseNumber: order.deliveryAddress.houseNumber,
      houseAddition: order.deliveryAddress.houseAdditions,
    });
  };
  const handleSetDeliveryPostcode = (e) => {
    setAddress({
      ...address,
      postcode: e.target.value,
      houseAddition: undefined,
    });
  };

  const handleSetDeliveryHouseNumber = (e) => {
    setAddress({
      ...address,
      houseNumber: e.target.value,
      houseAddition: undefined,
    });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    clearData();
    setOpenDialog(false);
  };

  const handleCloseConfirmDialog = () => {
    clearData();
    setOpenConfirmDialog(false);
  };

  const handleSetHouseAddition = (e) => {
    setAddress({ ...address, houseAddition: e.target.value });
    setResolvedAddress({ ...resolvedAddress, houseAdditions: e.target.value });
  };

  const handleResendTwoFactorCode = () => {
    setIsLoading(true);
    resendTwoFactorCodeMutation.mutate();
  };

  const addressChanged = (postcode, houseNumber) => {
    setErrorMessage("");
    if (!postcode) {
      return;
    }
    const normalizedPostcode = formatPostCode(postcode);
    const addressQueryValid =
      isPostcodeValid(normalizedPostcode) && parseInt(houseNumber);
    if (!addressQueryValid) {
      return;
    }
    setIsLoading(true);
    additionMutation.mutate({ postcode: normalizedPostcode, houseNumber });
  };

  const additionMutation = useMutation(findHouseAdditions, {
    onSuccess: (data) => {
      setErrorMessage("");
      setIsLoading(false);
      setHouseAdditions(data.houseAdditions);
      setResolvedAddress({
        city: data.city,
        street: data.street,
        coordinates: data.coordinates,
        houseNumber: address.houseNumber,
        houseAdditions: address.houseAddition || "",
        postcode: formatPostCode(address.postcode),
        country: defaultCountry,
      });
    },
    onError: (error) => {
      setResolvedAddress({});
      generateErrorMessage(error);
      setIsLoading(false);
    },
  });

  const refreshOrderMutation = useMutation(refreshOrders, {
    onSuccess: (data) => {
      const { mainOrder, referencedOrders } = data;
      if (referencedOrders && referencedOrders.length) {
        serReferenceOrders(referencedOrders);
      }
      setOrder(mainOrder);
      setIsLoading(false);
      clearData();
      setOpenConfirmDialog(false);
      setOpenSuccessDialog(true);
    },
    onError: (error) => {
      generateErrorMessage(error);
      setIsLoading(false);
    },
  });

  const resendTwoFactorCodeMutation = useMutation(resendTwoFactorCode, {
    onSuccess: () => {
      setIsLoading(false);
      setVerificationCode("");
      setOpenDialog(false);
      setOpenConfirmDialog(true);
    },
    onError: (error) => {
      generateErrorMessage(error);
      setIsLoading(false);
    },
  });

  const handleChangeAddress = () => {
    setIsLoading(true);
    const data = {
      trackingCode: order.trackingCode,
      address: resolvedAddress,
      code: verificationCode,
    };

    changeAddressMutation.mutate(data);
  };

  async function changeAddress(data) {
    const result = await postRequestUI("/change-delivery-address", data);
    return result;
  }

  async function resendTwoFactorCode() {
    await postRequestUI("/resend-two-factor-code/delivery-change", {
      trackingCode: order.trackingCode,
      twoFactorMethod: verificationMethod,
    });
  }

  async function findHouseAdditions(data) {
    const result = await getRequestUI("/tracking/house-additions", {
      postcode: data.postcode,
      houseNumber: data.houseNumber,
      trackingCode: order.trackingCode,
    });
    return result;
  }

  async function refreshOrders() {
    const result = await getRequestUI("/order/search", {
      trackingCode: order.trackingCode,
      zipcode: order.deliveryAddress.postcode,
    });
    return result;
  }

  const changeAddressMutation = useMutation(changeAddress, {
    onSuccess: () => {
      refreshOrderMutation.mutate();
    },
    onError: (error) => {
      generateErrorMessage(error);
      setIsLoading(false);
    },
  });

  const clearData = () => {
    setErrorMessage("");
    setHouseAdditions([]);
    setAddress({ ...address, houseAddition: undefined });
  };

  const generateErrorMessage = (error) => {
    if (error.code && error.code === "address-not-found") {
      setErrorMessage("Het adres is niet gevonden.");
      return;
    }
    if (error.code && error.code === "address-is-out-of-delivery-zone") {
      setErrorMessage("Het adres ligt buiten de bezorgzone.");
      return;
    }
    setErrorMessage(error.message);
    return;
  };

  return (
    <>
      <Button style={styles.button} onClick={handleOpenDialog}>
        Adres
        <br />
        wijzigen
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Grid container justifyContent={"flex-end"}>
          <IconButton aria-label="close" onClick={handleCloseDialog}>
            <Close />
          </IconButton>
        </Grid>
        <Grid container alignItems={"center"} direction={"column"}>
          <DialogTitle>Adress Wijzigen</DialogTitle>

          <DialogContentText>
            <span>
              Wil je de bezorgadres veranderen? Voer de nieuwe postcode en
              huisnummer
            </span>
            <br />
            <span style={{ paddingLeft: "110px" }}>
              hieronder in. Dit kan tot je pakket ingepland is
            </span>
          </DialogContentText>
        </Grid>
        <DialogContent>
          <Grid container>
            <Grid
              direction={"column"}
              padding={2}
              spacing={2}
              item
              xs={12}
              md={6}
              lg={6}
            >
              <Grid item>Postcode</Grid>
              <Grid item>
                <TextField
                  id="postcode"
                  variant="outlined"
                  value={address.postcode}
                  fullWidth
                  onChange={(e) => handleSetDeliveryPostcode(e)}
                />
              </Grid>
            </Grid>
            <Grid
              direction={"column"}
              padding={2}
              spacing={2}
              item
              xs={4}
              md={3}
              lg={3}
            >
              <Grid item>Nummer</Grid>
              <Grid item>
                <TextField
                  id="houseNumber"
                  variant="outlined"
                  value={address.houseNumber}
                  fullWidth
                  onChange={(e) => handleSetDeliveryHouseNumber(e)}
                />
              </Grid>
            </Grid>
            {houseAdditions && houseAdditions.length > 0 && (
              <Grid
                direction={"column"}
                padding={2}
                spacing={2}
                item
                xs={8}
                md={6}
                lg={6}
              >
                <Grid item>Toevoeging</Grid>
                <Grid item>
                  <TextField
                    select
                    id="houseAddition"
                    variant="outlined"
                    value={address.houseAddition}
                    fullWidth
                    onChange={(e) => handleSetHouseAddition(e)}
                  >
                    {houseAdditions.map((addition) => (
                      <MenuItem value={addition} key={addition}>
                        {addition}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            )}
            {!_.isEmpty(resolvedAddress) && (
              <Grid direction={"column"} padding={2} spacing={2} item xs={12}>
                <Grid item>Adres</Grid>
                <Grid item>
                  <IconButton>
                    <CheckCircleIcon />
                  </IconButton>
                  {`${resolvedAddress.city}, ${formatAddress(
                    resolvedAddress.postcode,
                    resolvedAddress.houseNumber,
                    resolvedAddress.houseAdditions,
                    resolvedAddress.street
                  )}`}
                </Grid>
              </Grid>
            )}
            {errorMessage && (
              <Grid direction={"column"} padding={2} spacing={2} item xs={12}>
                <Grid item style={styles.errorMessage}>
                  {errorMessage}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Box mt={2} />
          <Grid item>
            <Grid item>
              Om de aanpassing te bevestigen, wordt er een verificatiecode
              verstuurd
            </Grid>
            <Grid item>
              <TextField
                select
                value={verificationMethod}
                onChange={(e) => setVerificationMethod(e.target.value)}
                fullWidth
              >
                <MenuItem value="email">Ontvang code via Mail</MenuItem>
                {order.hasRecipientNumber && (
                  <MenuItem value="sms">Ontvang code via SMS</MenuItem>
                )}
              </TextField>
            </Grid>
          </Grid>
          <DialogActions>
            <Grid
              container
              alignItems={"center"}
              direction={"column"}
              spacing={2}
              paddingTop={2}
            >
              <Button
                onClick={handleResendTwoFactorCode}
                sx={styles.submitButton}
                disabled={_.isEmpty(resolvedAddress) || errorMessage}
              >
                Verzenden
              </Button>
              <Link style={styles.link} onClick={handleCloseDialog}>
                Annuleren
              </Link>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Grid container justifyContent={"flex-end"}>
          <IconButton aria-label="close" onClick={handleCloseConfirmDialog}>
            <Close />
          </IconButton>
        </Grid>
        <DialogContent>
          <Grid container gap={2} justifyContent={"center"}>
            <Grid container alignItems={"center"}>
              <DialogContentText>
                {`Er is ${
                  verificationMethod === "sms" ? "sms" : "mail"
                } gestuurd met een verificatiecode. Voer deze code in om de aanpassing te bevestigen`}
              </DialogContentText>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              direction={"column"}
              spacing={2}
              padding={2}
            >
              Verificatiecode:
              <VerificationInput
                value={verificationCode}
                length={4}
                validChars={"0-9"}
                autoFocus={true}
                classNames={{
                  container: "verifyContainer",
                  character: "verifyInput",
                  characterSelected: "verifyInputSelected",
                }}
                placeholder={""}
                onChange={(value) => {
                  setVerificationCode(value);
                }}
              />
            </Grid>
            <Grid
              container
              gap={2}
              justifyContent={"center"}
              alignContent={"center"}
            >
              {errorMessage && (
                <Grid direction={"column"} padding={2} spacing={2} item xs={12}>
                  <Grid item style={styles.errorMessage}>
                    {errorMessage}
                  </Grid>
                </Grid>
              )}
            </Grid>
            <DialogActions>
              <Grid
                container
                alignItems={"center"}
                direction={"column"}
                spacing={2}
                paddingTop={2}
              >
                <Button
                  sx={styles.submitButton}
                  onClick={handleChangeAddress}
                  disabled={
                    errorMessage ||
                    verificationCode.length < 4 ||
                    _.isEmpty(resolvedAddress)
                  }
                >
                  Verzenden
                </Button>
                <Link style={styles.link} onClick={handleCloseConfirmDialog}>
                  Annuleren
                </Link>
                <Link onClick={handleResendTwoFactorCode} style={styles.link}>
                  Verstuur verificatiecode opnieuw
                </Link>
              </Grid>
            </DialogActions>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
        fullWidth
      >
        <Grid container justifyContent="flex-end">
          <IconButton
            aria-label="close"
            onClick={() => setOpenSuccessDialog(false)}
          >
            <Close />
          </IconButton>
        </Grid>
        <DialogContent>
          <Grid
            container
            alignItems={"center"}
            direction={"column"}
            spacing={2}
            paddingBottom={2}
          >
            <DialogContentText
              id="success-dialog-description"
              style={{ textAlign: "center" }}
            >
              Uw aanpassing is succesvol gewijzigd.
            </DialogContentText>
          </Grid>
        </DialogContent>
      </Dialog>
      {isLoading && <CircularProgress sx={styles.spinner} />}
    </>
  );
};

const styles = {
  button: {
    lineHeight: "1.2",
    color: "black",
    backgroundColor: "white",
    textTransform: "none",
    width: "170px",
    height: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    padding: "10px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  submitButton: {
    color: "white",
    backgroundColor: theme.palette.error.main,
    borderRadius: 0,
    paddingLeft: 2,
    paddingRight: 2,
    "&:hover": {
      backgroundColor: theme.palette.error.dark, // Darken on hover
    },
    "&.Mui-disabled": {
      backgroundColor: "#eaeaea",
      color: "#c0c0c0",
    },
  },
  link: {
    cursor: "ponter",
    color: "black",
  },
  spinner: {
    right: "50%",
    top: "50%",
    position: "absolute",
    zIndex: 9999,
  },
  verifyCodeContainer: {
    display: "flex",
    gap: "15px",
  },
  errorMessage: {
    color: theme.palette.error.main,
    textAlign: "center",
  },
};

export default ChangeOrderDeliveryAddress;
