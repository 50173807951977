import React, { useContext } from "react";
import TrackingOrder from "./TrackingOrder";
import { Redirect, useParams } from "react-router-dom";
import { OrderContext } from "../context";

const TrackingPage = () => {
  const { tracking, postcode } = useParams();
  const { order } = useContext(OrderContext);
  return (
    <>
      {!order ? (
        <Redirect to={`/${tracking}/${postcode}`} />
      ) : (
        <TrackingOrder />
      )}
    </>
  );
};

export default TrackingPage;
