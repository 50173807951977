import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import TrackingApp from "./pages/TrackingApp";

class Main extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/:tracking?/:postcode?" component={TrackingApp} />
      </Switch>
    );
  }
}

export default Main;
