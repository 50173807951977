import { createContext, useState } from "react";

export const useInitContext = () => {
  const [order, setOrder] = useState(null);
  const [referenceOrders, serReferenceOrders] = useState([]);
  const [error, setError] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  return {
    activeStep,
    order,
    referenceOrders,
    error,
    setActiveStep,
    setOrder,
    serReferenceOrders,
    setError,
  };
};
export const OrderContext = createContext({
  activeStep: 0,
  order: {},
  referenceOrders: [],
  error: "",
  setActiveStep: () => {},
  setOrder: () => {},
  serReferenceOrders: () => [],
  setError: () => {},
});
